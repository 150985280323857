import Home from './container/Home';
import './styles/App.css';
import './styles/index.css';
import './styles/animation.css';

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
